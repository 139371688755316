<template>
  <b-container class="container-content">
    <div class="page-header page-header-center">
      <h4 class="page-header-text">{{ $t('pages.contact_us.heading') }}</h4>
    </div>
    <b-row class="justify-content-center">
      <b-col xl="6">
        <small>{{ $t('pages.contact_us.please_feel_free_to_contact_us') }}</small>
        <b-card class="card-contact-us mb-4 mt-3">
          <b-img alt="bluuu logo" :src="require('../assets/images/bluuu_logo.png')"></b-img>
          <b-card-title>{{ $t('pages.contact_us.hi') }} {{ first_name }}</b-card-title>
          <b-card-text>{{ $t('pages.contact_us.do_you_want_us_to_help') }}</b-card-text>
          <!-- <hr />
          <b-button
            variant="outline-primary"
            block
            @click="onClickChatNow"
            :disabled="!isChannelWithAdmin && isLoggedIn"
          >
            <i class="fas fa-comment-alt"></i>
            {{ $t('pages.contact_us.chat_now') }}
          </b-button> -->
        </b-card>
        <b-form>
          <b-form-group :label="$t('form_label.full_name')">
            <b-form-input v-model="form.name"></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('form_label.email')">
            <b-form-input v-model="form.email" type="email"></b-form-input>
          </b-form-group>
          <div class="sub-heading mb-3 text-red" v-if="isValidEmail === false">{{ $t('form_label.email_err_msg') }}</div>
          <b-form-group :label="$t('form_label.which_best_describes_your_current_feeling')">
            <b-row align-h="center">
              <b-col
                v-for="feeling in feelings"
                :key="feeling"
                @click="form.help = feeling"
                class="feeling"
                cols="3"
                sm="auto"
              >
                <b-img
                  alt="feeling"
                  :src="getFeelingImg(feeling)"
                  class="feeling-icon"
                  :class="{ active: form.help === feeling }"
                ></b-img>
                <span class="text-capitalize">{{ $t(`emoticon.${feeling}`) }}</span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group :label="$t('form_label.your_message')">
            <b-form-textarea rows="3" v-model="form.message"></b-form-textarea>
          </b-form-group>
          <b-button variant="primary" :disabled="!canSend || isSending" block @click="send">
            {{ $t('button.send_message') }}
          </b-button>
        </b-form>
      </b-col>
    </b-row>
    <BaseModal id="contact-us-modal">
      <div v-if="modalType === 'Confirm'">
        <p>{{ $t('modal.your_message_has_been_sent') }}</p>
      </div>
      <div v-else>
        <p>{{ $t('warning.please_login_first') }}</p>
        <b-button block @click="doLogin" variant="primary">{{ $t('button.sign_in') }}</b-button>
      </div>
    </BaseModal>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import BaseModal from '../components/Modal/BaseModal'
import ApiClient from '../services'
// import { ADMIN_ID } from '../store/sbConstants'
// import { createMeta } from '../config/seo'

const UserStore = createNamespacedHelpers('user')
const AuthStore = createNamespacedHelpers('auth')
// const sbUser = createNamespacedHelpers('sbUser')
// const sbChannel = createNamespacedHelpers('sbChannel')

@Component({
  computed: {
    ...UserStore.mapState(['email', 'first_name', 'last_name']),
    ...AuthStore.mapState(['isLoggedIn']),
    // ...sbUser.mapState(['sbUser']),
    // ...sbChannel.mapState(['sbChannelList']),
    // isChannelWithAdmin() {
    //   return (
    //     this.sbChannelList && this.sbChannelList.find(o => o.memberMap.hasOwnProperty(ADMIN_ID))
    //   )
    // }
  },
  methods: {
    ...AuthStore.mapActions(['signIn'])
  },
  components: { BaseModal },
  // metaInfo() {
  //   return createMeta({
  //     title: this.$i18n.locale === 'th' ? 'ค่าบริการ' : 'Pricing',
  //     lang: this.$i18n.locale
  //   })
  // }
})
export default class ContactUs extends Vue {
  feelings = ['excited', 'neutral', 'wondering', 'confused', 'worried', 'upset', 'angry', 'panic']
  form = {
    name: '',
    help: '',
    email: '',
    message: ''
  }
  modalType = ''

  isSending = false

  async send() {
    try {
      this.isSending = true
      const result = await ApiClient.contactUs({
        ...this.form,
        email: this.form.email,
        user_lang: this.$i18n.locale
      })
      console.log(result)
      if (result.data) {
        this.resetForm()
      }
      this.showSuccessModal()
    } catch (e) {
      console.error(e.response)
      this.resetForm()
    } finally {
      this.isSending = false
    }
  }

  resetForm() {
    this.form.help = ''
    this.form.message = ''
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`
  }

  mounted() {
    if (this.first_name) {
      this.form.name = this.fullName
      this.form.email = this.email
    }
  }

  showSuccessModal() {
    this.modalType = 'Confirm'
    this.$root.$emit('bv::show::modal', 'contact-us-modal')
  }

  @Watch('first_name')
  onProfileLoaded(newVal, oldVal) {
    if (newVal && !oldVal) {
      this.form.name = this.fullName
      this.form.email = this.email
    }
  }

  get canSend() {
    const { name, help, message, email } = this.form
    return name.length > 0 && message.length > 0 && this.isValidEmail
  }

  get isValidEmail() {
    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*\.[a-zA-Z0-9]{2,}$/;
    return emailPattern.test(String(this.form.email).toLowerCase())
  }

  getFeelingImg(feeling) {
    return require(`../assets/images/contact_us/${feeling}.svg`)
  }

  onClickChatNow() {
    window.open('https://m.me/bluuu.co')
    // if (!this.isLoggedIn) {
    //   window.open('https://m.me/bluuu.co')
    // } else {
    //   this.$router.push({
    //     path: `/chats/message/${this.sbUser.userId}/${this.isChannelWithAdmin.url}`
    //   })
    // }
  }

  doLogin() {
    this.$root.$emit('bv::hide::modal', 'contact-us-modal')
    this.signIn()
  }
}
</script>

<style lang="scss" scoped>
.feeling {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 14px;
  }
}
.feeling-icon {
  opacity: 0.25;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  margin: 4px 0;
  &:hover,
  &.active {
    opacity: 1;
    transform: scale(1.2);
  }
}
.sub-heading {
  @media (max-width: 767px) {
    font-size: 13px;
  }
}

.text-red {
  color: #ff695b !important;
}
</style>
